import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

const Button = props => {
    const {
        className,
        text,
        children,
        onClick,
        disabled,
        id,
        type,
        gaEventClass = "",
        dataActionDetail,
        variant = "primary",
    } = props
    const finalClassName = clsx(variant === "primary" && "btn-primary", gaEventClass, className)

    return (
        <button
            className={finalClassName}
            data-action-detail={dataActionDetail}
            onClick={onClick}
            disabled={disabled}
            id={id}
            type={type}
        >
            {children || text}
        </button>
    )
}

Button.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    type: PropTypes.string,
    gaEventClass: PropTypes.string,
    dataActionDetail: PropTypes.string,
    variant: PropTypes.oneOf(["primary"]),
}

export default Button
