import React from "react"
import { v4 as uuidv4 } from "uuid"
const voidElements = [
    "area",
    "base",
    "br",
    "col",
    "embed",
    "hr",
    "img",
    "input",
    "link",
    "meta",
    "param",
    "source",
    "track",
    "wbr",
]

const parseJsonToJsx = node => {
    if (typeof node === "string") {
        return node
    }
    const { type, props = {}, children = [] } = node
    if (type === "Fragment") {
        return <React.Fragment>{children.map((child, index) => parseJsonToJsx(child, index))}</React.Fragment>
    }
    if (voidElements.includes(type)) {
        return React.createElement(type, { ...props, key: props.key || uuidv4() })
    }
    return React.createElement(
        type,
        { ...props, key: props.key || uuidv4() },
        children.map((child, index) => parseJsonToJsx(child, index))
    )
}

export { parseJsonToJsx }
