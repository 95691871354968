const { fragment: RichTextFragment } = require("../RichTextModel/RichText.fragment")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")

const fragment = `
    internalTitle
    variant
    title
    richText{
        ${RichTextFragment}
    }
    link {
        ${LinkFragment}
    }
    domId
`

const query = (id, isPreview) => `
{
  richTextModel(id: "${id}", preview: ${isPreview}) {
    ${fragment}
  }
}
`
module.exports = { query, fragment }
