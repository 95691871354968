export const defaultStyle = {
    footer: "relative w-full bg-purple z-[30]",
    wrapper: "section-wrapper flex flex-col items-center gap-[25px] py-[25px] md:py-[50px]",
    logoWrapper: "max-w-[156px] md:max-w-[240px]",
    copyright: "font-bwSurco font-normal text-white text-center text-[14px] leading-[18px]",
    socialLinksWrapper: "flex flex-wrap items-center justify-center gap-[10px]",
    socialLink: "block [&_img]:w-[36px] [&_img]:h-[36px] [&_img]:max-w-[36px] [&_img]:max-h-[36px]",
    baseLinksWrapper: "flex flex-wrap items-center justify-center gap-x-[10px]",
    baseLink: "font-bwSurco font-normal text-white text-center text-[14px] leading-[18px] hover:underline",
}
