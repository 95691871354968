import React from "react"
import PropTypes from "prop-types"
import { Form as FormikForm, Formik } from "formik"
import FormElement from "./FormElement"
import useCampaignForm from "../../hooks/useCampaignForm"
import Button from "../UI/Button/Button"

const FormFields = props => {
    const { formData, style } = props
    return (
        <div className={style.wrapper}>
            {formData?._fields?.map(field => {
                return (
                    <div key={field.key} className={`${field?.gridClass ?? "col-span-10"}`}>
                        <FormElement _form={formData._form} {...field} style={style} />
                    </div>
                )
            })}
        </div>
    )
}

const SubmitButton = props => {
    const { formData, isSubmitting, style } = props
    if (!formData?.submit) return null
    return (
        <Button
            type="submit"
            disabled={isSubmitting}
            className={style.submitButton}
            gaEventClass={formData?.submit?.gaEventClass}
            dataActionDetail={formData?.submit?.dataActionDetail}
            text={formData?.submit?.text ?? "Submit"}
            variant="primary"
        />
    )
}
function Form(props) {
    const { formData, style, apiCallback } = props
    const { initialValues, reviewSchema, submitHandler } = useCampaignForm({ formData, apiCallback })
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitHandler}
            validationSchema={reviewSchema}
            key={formData._form}
        >
            {formikProps => {
                const { isSubmitting } = formikProps
                return (
                    <FormikForm>
                        <div className={style.formikContent}>
                            <FormFields formData={formData} style={style.formFields} />
                            <SubmitButton formData={formData} isSubmitting={isSubmitting} style={style} />
                        </div>
                    </FormikForm>
                )
            }}
        </Formik>
    )
}

export default Form

Form.propTypes = {
    style: PropTypes.object,
    formData: PropTypes.object,
    apiCallback: PropTypes.func,
}
FormFields.propTypes = {
    style: PropTypes.object,
    formData: PropTypes.object,
}
SubmitButton.propTypes = {
    style: PropTypes.object,
    formData: PropTypes.object,
    isSubmitting: PropTypes.bool,
}
