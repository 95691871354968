import React from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import RichTextModel from "../RichTextModel/RichTextModel"
import DangerousHtml from "../DangerousHtml/DangerousHtml"
import Link from "../UI/Link/Link"
import CaretLeftIcon from "../../assets/CaretLeftIcon"

const PopupSection = props => {
    const { style, brandLogo, backButton, contentCollection } = props
    const items = contentCollection?.items
    if (!items || items.length === 0) return null
    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <div className={style.content}>
                    {!!brandLogo && (
                        <div className={style.brandLogoWrapper}>
                            <img
                                src={brandLogo?.url}
                                alt={brandLogo?.title || "Away From Home"}
                                width={brandLogo?.width}
                                height={brandLogo?.height}
                            />
                        </div>
                    )}
                    <div className={style.textWrapper}>
                        <div className={style.backButtonWrapper}>
                            <Link {...backButton} className={style.backButton}>
                                <CaretLeftIcon />
                                <span>{backButton?.title || backButton?.label || "Back"}</span>
                            </Link>
                        </div>
                        {items.map(item => {
                            const { __typename } = item
                            if (__typename === "RichTextModel") {
                                return <RichTextModel key={uuidv4()} {...item} />
                            } else if (__typename === "DangerousHtml") {
                                return <DangerousHtml key={uuidv4()} {...item} />
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

PopupSection.propTypes = {
    style: PropTypes.object,
    brandLogo: PropTypes.object,
    backButton: PropTypes.object,
    contentCollection: PropTypes.object,
}

export default PopupSection
