import React, { useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./CampaignForm-tw-styles"
import RichTextModel from "../RichTextModel/RichTextModel"
import useCampaignFormViewManager from "../../hooks/useCampaignFormViewManager"
import Form from "./Form"

function CampaignForm(props) {
    const { brandLogo, titleImage, formViewsCollection } = props
    const style = styles.defaultStyle
    const views = formViewsCollection?.items || []
    const { currentView, apiCallback, handlePreviousView, handleNextView, currentViewName } =
        useCampaignFormViewManager(views)
    return (
        <div className={style.wrapper}>
            {!!brandLogo && (
                <div className={style.brandLogoWrapper}>
                    <img
                        src={brandLogo?.url}
                        alt={brandLogo?.title || "Away From Home"}
                        width={brandLogo?.width}
                        height={brandLogo?.height}
                    />
                </div>
            )}
            {!!titleImage && (
                <div className={style.titleImageWrapper}>
                    <img
                        src={titleImage?.url}
                        alt={titleImage?.title || "Unwrap the opportunity"}
                        width={titleImage?.width}
                        height={titleImage?.height}
                    />
                </div>
            )}
            <div className={style.content}>
                {currentView?.description?.json && (
                    <RichTextModel richText={currentView?.description} style={style.copy} />
                )}
                {currentView?.formData && (
                    <Form formData={currentView?.formData} style={style.form} apiCallback={apiCallback} />
                )}
            </div>
            {/* For debugging */}
            {process.env.GATSBY_ACTIVE_ENV === "development" && (
                <div className="w-full z-[20] bottom-0 left-0 absolute py-4 bg-[rgba(255,255,255,0.5)] flex justify-between items-center text-center opacity-20 hover:opacity-100">
                    <button type="button" className="underline" onClick={handlePreviousView}>
                        Previous View
                    </button>
                    <span>{currentViewName}</span>
                    <button type="button" className="underline" onClick={handleNextView}>
                        Next View
                    </button>
                </div>
            )}
        </div>
    )
}

export default CampaignForm

CampaignForm.propTypes = {
    brandLogo: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    titleImage: PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    formViewsCollection: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.oneOf(["formView", "thankYouView"]),
                description: PropTypes.object,
                formData: PropTypes.object,
            })
        ),
    }),
}
