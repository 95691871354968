export const defaultStyle = {
    wrapper: "",
    richTextWrapper: "",
    code: "",
    link: "",
    paragraph: "",
    heading1: "",
    heading2: "",
    heading3: "",
    heading4: "",
    heading5: "",
    heading6: "",
    table: "",
    tRow: "",
    tHeader: "",
    tData: "",
    ulList: "",
    list: "",
    title: "",
    mainLink: "",
}

export const TERMS_AND_CONDITIONS = {
    wrapper: "text-white font-bwSurco",
    heading3: "font-normal text-[20px] leading-[16px] mb-[16px]",
    paragraph: "font-normal text-[12px] leading-[16px] [&_a]:underline",
    ulList: "list-disc ml-[12px] font-normal text-[12px] leading-[16px] [&_a]:underline",
    list: "list-disc ml-[12px] font-normal text-[12px] leading-[16px] [&_a]:underline",
}
export const COOKIES = {
    wrapper: "text-white font-bwSurco",
    heading3: "font-normal text-[20px] leading-[16px] mb-[16px]",
    paragraph: "font-normal text-[12px] leading-[16px] [&_a]:underline",
    ulList: "list-disc ml-[12px] font-normal text-[12px] leading-[16px] [&_a]:underline",
    list: "list-disc ml-[12px] font-normal text-[12px] leading-[16px] [&_a]:underline",
}
