import React from "react"
import PropTypes from "prop-types"
import { motion, useTransform } from "framer-motion"

const PopupWrapper = ({ children, style, popupTimeline }) => {
    const popupOpacity = useTransform(popupTimeline, [0, 1], [0, 1])
    const y = useTransform(popupTimeline, [0, 1], ["0vh", `-80vh`])

    return (
        <motion.div
            className={style.popupWrapper}
            style={{
                opacity: popupOpacity,
                top: y,
                marginBottom: y,
            }}
        >
            <div className={style.popupInner}>
                <div className={style.popupContent}>{children}</div>
            </div>
        </motion.div>
    )
}

PopupWrapper.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    popupTimeline: PropTypes.object,
}

export default PopupWrapper
