import { useEffect, useState } from "react"
import tailwindConfig from "../../tailwind.config"

const useScreenSize = () => {
    const BP_XSM = +tailwindConfig?.theme?.screens?.xsm.replace("px", "") || 376
    const BP_SM = +tailwindConfig?.theme?.screens?.sm.replace("px", "") || 402
    const BP_MD = +tailwindConfig?.theme?.screens?.md.replace("px", "") || 768
    const BP_LG = +tailwindConfig?.theme?.screens?.lg.replace("px", "") || 1440
    const BP_XL = +tailwindConfig?.theme?.screens?.xl.replace("px", "") || 1728

    const [width, setWidth] = useState(null)

    const handleResize = () => setWidth(window.innerWidth)

    useEffect(() => {
        if (typeof window !== "undefined") {
            // Add event listener
            window.addEventListener("resize", handleResize)

            // Call handler right away so state gets updated with initial window size
            handleResize()
            return () => {
                // Remove event listener on cleanup
                window.removeEventListener("resize", handleResize)
            }
        }
    }, []) // Empty array ensures that effect is only run on mount

    if (!width) {
        return null
    }

    return {
        width,
        isExtraSmallPhone: width < BP_XSM,
        isSmallPhone: width < BP_SM,
        isMobile: width < BP_MD,
        isTablet: width >= BP_MD && width < BP_LG,
        isDesktop: width >= BP_LG && width < BP_XL,
        isLargeDesktop: width >= BP_XL,
        isMobileOrTablet: width < BP_LG,
    }
}

export default useScreenSize
