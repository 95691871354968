import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import useScreenSize from "../../hooks/useScreenSize"
import ReCAPTCHA from "react-google-recaptcha"
import { parseJsonToJsx } from "../../helpers/reactHelpers"

const renderInput = props => {
    const { _form, name, placeholder, label, isRequired, style: _style } = props
    const style = _style.input
    return (
        <Field name={name}>
            {({ field, meta }) => {
                return (
                    <div className={style.wrapper}>
                        {!!label && (
                            <label className={style.label} htmlFor={`${_form}_input_${name}`}>
                                {label}
                                {isRequired && " *"}
                            </label>
                        )}
                        <input
                            className={`${style.input} ${meta.touched && meta.error ? style.errored : ""}`}
                            type={"text"}
                            id={`${_form}_input_${name}`}
                            name={name}
                            placeholder={placeholder}
                            required={isRequired}
                            {...field}
                        />
                        {meta.touched && meta.error && <div className={style.errorMessage}>{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}
const renderCheckbox = props => {
    const { _form, name, label, isRequired, style: _style } = props
    const style = _style.checkbox
    return (
        <Field name={name}>
            {({ field, meta }) => {
                return (
                    <div className={style.wrapper}>
                        <div className={style.checkboxWrapper}>
                            <input
                                id={`${_form}_checkbox_${name}`}
                                type={"checkbox"}
                                name={name}
                                required={isRequired}
                                checked={field.value}
                                {...field}
                            />
                            <label htmlFor={`${_form}_checkbox_${name}`} className={style.label}>
                                <span>
                                    {parseJsonToJsx(label)}
                                    {isRequired && " *"}
                                </span>
                            </label>
                        </div>
                        {meta.touched && meta.error && <div className={style.errorMessage}>{meta.error}</div>}
                    </div>
                )
            }}
        </Field>
    )
}
const renderRecaptcha = props => {
    const { _form, name, language, isRequired, style: _style } = props
    const style = _style.recaptcha
    const screenSize = useScreenSize()
    const recaptchaSize = screenSize?.isExtraSmallPhone ? "compact" : "normal"

    const handleRecaptchaChange = (value, form, name) => {
        form.setFieldValue(name, value)
        form.setFieldTouched(name, true)
    }

    if (!process.env.GATSBY_CDIL_CAPTCHA) {
        console.error("GATSBY_CDIL_CAPTCHA captcha key not found")
        return null
    }
    return (
        <Field name={name} key={`${_form}_captcha_${name}`}>
            {({ field, form, meta }) => {
                return (
                    <div className={style.wrapper}>
                        <div className={style.captchaWrapper}>
                            <ReCAPTCHA
                                key={recaptchaSize}
                                sitekey={process.env.GATSBY_CDIL_CAPTCHA}
                                onChange={e => handleRecaptchaChange(e, form, field.name)}
                                hl={language || "en"}
                                size={recaptchaSize}
                            />
                            {isRequired && <span className={style.requiredIndicator}> *</span>}
                        </div>
                        {meta.touched && meta.error && !meta.value && (
                            <div className={style.errorMessage}>{meta.error}</div>
                        )}
                    </div>
                )
            }}
        </Field>
    )
}

function FormElement(props) {
    switch (props.type) {
        case "input":
            return renderInput(props)
        case "checkbox":
            return renderCheckbox(props)
        case "recaptcha":
            return renderRecaptcha(props)
        default:
            return null
    }
}

export default FormElement

FormElement.propTypes = {
    style: PropTypes.object,
    _form: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["input", "checkbox", "recaptcha"]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gridClass: PropTypes.string,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    language: PropTypes.string,
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            message: PropTypes.string,
        })
    ),
}
