export const defaultStyle = {
    wrapper: "relative flex items-start h-[500vh] bg-purple",
    container: "fixed flex flex-col top-[0px] w-full min-h-[100vh]",
    bubblesWrapperMobile:
        "relative section-wrapper pt-[15px] block overflow-visible [&>svg]:w-full [&>svg]:h-full [&>svg]:overflow-visible [&>svg]:origin-[50%_100%]",
    bubblesWrapperDesktop:
        "relative block mt-[-4px] xl:mt-[-40px] overflow-visible [&>svg]:w-full [&>svg]:h-full [&>svg]:overflow-visible [&>svg]:origin-[50%_100%]",
    centerLogoWrapper:
        "relative flex items-center justify-center w-[150px] sm:w-[209px] md:w-[287px] [&_img]:w-full [&_img]:h-full mx-auto mt-[-50px] md:mt-[-50px] lg:mt-[-130px]",
    scrollDownPrompt: "flex w-fit mt-auto mx-auto mb-[95px] animate-bounce",
    popupWrapper: "relative bg-purple mt-[-1px]",
    popupInner: "relative section-wrapper",
    popupContent: "bg-darkPurple max-w-[805px] mx-auto rounded-[35px]",
}
