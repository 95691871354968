import React from "react"

function ScrollDownIcon() {
    return (
        <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M31.6956 0.699595C22.0963 -1.04554 7.35654 -1.069 0.698657 19.6709C-1.64981 26.9846 0.98813 53.0866 23.2636 54.8974C36.7334 55.9905 50.1052 48.2922 53.3454 34.5609C56.7397 20.1494 46.5475 3.39705 31.6956 0.699595Z"
                fill="#4F2170"
            />
            <path
                d="M26.7859 37.7965C26.017 39.1346 24.0865 39.1346 23.3177 37.7965L15.452 24.1077C14.6859 22.7744 15.6483 21.1113 17.1861 21.1113L32.9174 21.1113C34.4552 21.1113 35.4177 22.7744 34.6515 24.1077L26.7859 37.7965Z"
                fill="#FDFAF2"
            />
        </svg>
    )
}

export default ScrollDownIcon
