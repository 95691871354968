export const defaultStyle = {
    wrapper: "relative section-wrapper",
    inner: "bg-darkPurple max-w-[805px] mx-auto rounded-[35px]",
    textWrapper: "relative",
    backButtonWrapper: "absolute top-0 right-0 translate-y-[-100%]",
    backButton:
        "flex flex-row items-center justify-center gap-[5px] text-white font-bwSurco font-normal text-[14px] leading-[18px]",
    content:
        "relative w-full flex flex-col gap-[30px] md:gap-[50px] px-[20px] xsm:px-[28px] sm:px-[36px] py-[50px] md:px-[75px]",
    brandLogoWrapper: "relative flex items-center justify-center w-[161px] md:w-[171px] mx-auto",
}
