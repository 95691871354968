const fragment = `
    content
    variant
`

const query = (id, isPreview) => `
{
    dangerousHtml(id: "${id}", preview: ${isPreview}) {
        ${fragment}
    }
}
`
module.exports = { query, fragment }
