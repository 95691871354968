const query = (isPreview, locale) => `{
  headerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      internalTitle
    }
  }
}
`

module.exports = { query }
