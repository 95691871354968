import React, { useRef } from "react"
import PropTypes from "prop-types"
import { motion, useTransform, useScroll } from "framer-motion"
import CampaignForm from "../CampaignForm/CampaignForm"
import PopupWrapper from "./PopupWrapper"
import Bubbles from "./Bubbles"
import ScrollDownIcon from "../../assets/ScrollDownIcon"
import { gaEventClasses, gaEventLabels } from "../../constants/gtm.constants"
import { smoothScrollToEnd } from "../../helpers/utils"

const AnimationWrapper = props => {
    const { style, centerLogo, popupContent } = props
    const wrapperRef = useRef(null)

    const { scrollYProgress } = useScroll({
        target: wrapperRef,
        offset: ["0, 0", "1, 1"],
    })
    const scrollDownClickHandler = () => {
        if (wrapperRef?.current) {
            smoothScrollToEnd(wrapperRef.current, 3000)
        }
    }

    const bubblesTimeline = useTransform(scrollYProgress, [0, 1], [0, 1])
    const logoOpacity = useTransform(scrollYProgress, [0.6, 1], [1, 0])
    const popupTimeline = useTransform(scrollYProgress, [0.6, 1], [0, 1])
    return (
        <>
            <div className={style.wrapper} ref={wrapperRef}>
                <div className={style.container}>
                    <Bubbles timeline={bubblesTimeline} />
                    {!!centerLogo && (
                        <motion.div className={style.centerLogoWrapper} style={{ opacity: logoOpacity }}>
                            <img
                                src={centerLogo?.url}
                                alt={centerLogo?.title || "Unwrap the opportunity"}
                                width={centerLogo?.width}
                                height={centerLogo?.height}
                            />
                        </motion.div>
                    )}
                    <motion.button
                        className={`${style.scrollDownPrompt} ${gaEventClasses.button_click}`}
                        data-action-detail={gaEventLabels.scrollDown}
                        onClick={scrollDownClickHandler}
                        style={{ opacity: logoOpacity }}
                    >
                        <ScrollDownIcon />
                    </motion.button>
                </div>
            </div>
            <PopupWrapper style={style} popupTimeline={popupTimeline}>
                {!!popupContent && <CampaignForm {...popupContent} />}
            </PopupWrapper>
        </>
    )
}

AnimationWrapper.propTypes = {
    style: PropTypes.object,
    centerLogo: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    popupContent: PropTypes.object,
}

export default AnimationWrapper
