import { sortOptions } from "../mocks/sort.mocks"

/**
 * returns value from object by keys path as an array
 * e.g.
 * obj = { a: { b: 'b value', c: 'c value' }}; keysArray = ['a', 'c'];
 * getValueByKeysArray(obj, keysArray); // will returns 'c value'
 *
 * @export
 * @param {Object} obj Object to be passed for a value
 * @param {Array} keysArray Array of keys
 * @returns
 */
export function getValueByKeysArray(obj, keysArray) {
    let val = obj
    if (Array.isArray(keysArray)) {
        keysArray.forEach(key => {
            if (key > "") {
                val = val && val[key]
            }
        })
    }

    return val
}

/**
 * get value from object by dot notation string
 * e.g.
 * from object
 * {
 *   addresses: [
 *     street: 'Street Name',
 *   ]
 * }
 *
 * return for dot notation string 'addresses[0].street' value 'Street Name'
 *
 * @export
 * @param {Object} obj Object to be passed for a value
 * @param {String} path Dot notation string to be parsed and used to get value from object
 * @param {*} defaultValue Default value if value can't be found - optional
 * @returns
 */
export const get = (obj, path, defaultValue) => {
    if (typeof path != "string") {
        throw new Error("path must be string value")
    }

    const keysArr = path
        .replace(/(\[)|(\].?)/g, ".")
        .replace(/\.$/, "")
        .split(".")
    const value = getValueByKeysArray(obj, keysArr)

    // undefined can't be serialized, so null value has to returns
    return value || (defaultValue != null || defaultValue !== undefined ? defaultValue : null)
}

export const fillStringTemplate = (template, vars) =>
    Object.entries(vars).reduce((acc, [key, value]) => acc.replace(new RegExp(`{${key}}`, "g"), value), template)

// deep cloning object to avoid object reference
export const deepCloneSimpleObject = obj => {
    if (obj) return JSON.parse(JSON.stringify(obj))
}

// sort any array by A-z, Z-A, date, number
export const sortCollection = (collectionArr, sortDpValue) => {
    const sortingObject = sortOptions[sortDpValue]
    return sortByOrder(collectionArr, sortingObject.key, sortingObject.order)
}

const sortByOrder = (arr, key, order) => {
    return arr?.sort((a, b) => {
        const d1 = key === "date" ? new Date(a[key]) : a[key]
        const d2 = key === "date" ? new Date(b[key]) : b[key]
        if (d1 > d2) {
            return order === "asc" ? 1 : -1
        }
        if (d2 > d1) {
            return order === "asc" ? -1 : 1
        }
        return 0
    })
}

// filter any array based on value and field referring to
export const filterByKey = (arr, key, matchedVal) => {
    return arr && arr?.filter(item => item[key] === matchedVal)
}

export const unitChangeIngredient = (qty, item, prevCount, servingsCount) => {
    const conversionVal = 0.0022046
    if (qty === "g" && item.QuantityUnit === "lbs") {
        return {
            ...item,
            QuantityText: Number((item.QuantityText / conversionVal).toFixed(0)),
            QuantityUnit: qty,
        }
    } else if (qty === "lbs" && item.QuantityUnit === "g") {
        return {
            ...item,
            QuantityText: Number((item.QuantityText * conversionVal).toFixed(3)),
            QuantityUnit: qty,
        }
    } else if (!qty && prevCount && servingsCount) {
        return {
            ...item,
            QuantityText: Number(((item.QuantityText / prevCount) * servingsCount).toFixed(3)),
        }
    } else return item
}
export const deslashify = origUrl => {
    const url = origUrl?.trim().endsWith("/") ? origUrl.slice(0, -1) : origUrl
    return url
}

export const smoothScrollToEnd = (element, totalDuration = 1000) => {
    const targetPosition = element.getBoundingClientRect().bottom - window.innerHeight + window.scrollY
    const startPosition = window.scrollY
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight
    const remainingDistance = targetPosition - startPosition
    const fullDistance = maxScroll
    const duration = (Math.abs(remainingDistance) / fullDistance) * totalDuration

    let startTime = null

    function animation(currentTime) {
        if (!startTime) startTime = currentTime
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / duration, 1)

        window.scrollTo(0, startPosition + remainingDistance * progress)

        if (elapsedTime < duration) {
            requestAnimationFrame(animation)
        }
    }

    requestAnimationFrame(animation)
}
