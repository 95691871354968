const { fragment: RichTextModelFragment } = require("../RichTextModel/RichTextModel.query")
const { fragment: DangerousHtmlFragment } = require("../DangerousHtml/DangerousHtml.query")
const { fragment: LinkFragment } = require("../UI/Link/Link.fragment")
const query = (id, isPreview) => `
{
    popupSection(id: "${id}", preview: ${isPreview}) {
        brandLogo {
            title
            url
            width
            height
        }
        backButton {
            ${LinkFragment}
        }
        contentCollection(limit: 10) {
            items {
                __typename
                ... on RichTextModel {
                    ${RichTextModelFragment}
                }
                ... on DangerousHtml {
                    ${DangerousHtmlFragment}
                }
            }
        }
    }
}
`
module.exports = { query }
