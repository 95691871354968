const { fragment: CampaignFormFragment } = require("../CampaignForm/CampaignForm.fragment")
const query = (id, isPreview) => `
{
    animationWrapper(id: "${id}", preview: ${isPreview}) {
        internalTitle
        centerLogo {
            title
            url
            width
            height
        }
        popupContent {
            ... on CampaignForm {
                ${CampaignFormFragment}
            }
        }
    }
}
`
module.exports = { query }
