// /**
//  * Layout component that queries for data
//  * with Gatsby's useStaticQuery component
//  *
//  * See: https://www.gatsbyjs.com/docs/use-static-query/
//  */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "../Globals/Header/Header"
import Footer from "../Globals/Footer/Footer"
import { get } from "../../helpers/utils"
import ScriptWrapper from "../Wrappers/ScriptWrapper/ScriptWrapper"
import { pageTypes } from "../../constants/pageType.constants"
import clsx from "clsx"
import Bubbles from "../AnimationWrapper/Bubbles"

const Layout = props => {
    const { children, seoData, globalComponents, pageLanguage, pageType } = props
    const FooterProps = globalComponents?.find(component => component?.Footer)
    const HeaderProps = globalComponents?.find(component => component?.Header)
    const footerCollection = get(FooterProps, "Footer.footerCollection.items[0]", {})
    const headerCollection = get(HeaderProps, "Header.headerCollection.items[0]", {})
    const mainContainerClassName = clsx(
        "main-container",
        pageType === pageTypes.staticPageWithPopupContent && "static-page-with-popup-content"
    )

    return (
        <>
            <ScriptWrapper seoHead={seoData} />
            <div media="print" className="overflow-hidden relative">
                <Header {...headerCollection} pageLanguage={pageLanguage} />
                <main className={mainContainerClassName}>
                    {pageType === pageTypes.staticPageWithPopupContent && <Bubbles />}
                    {children}
                </main>
                <Footer {...footerCollection} />
            </div>
            <div id="modal-root"></div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    seoData: PropTypes.object,
    globalComponents: PropTypes.array,
    pageLanguage: PropTypes.string,
    pageType: PropTypes.string,
}

export default Layout
