const formViewFragment = `
    internalTitle
    name
    description {
        json
    }
    formData
`

const fragment = `
    internalTitle
    brandLogo {
        title
        url
        width
        height
    }
    titleImage {
        title
        url
        width
        height
    }
    formViewsCollection(limit: 2) {
        items {
            ${formViewFragment}
        }
    }
`
module.exports = { fragment }
