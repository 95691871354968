import * as yup from "yup"
import { submitCampaignForm } from "../service/CampaignService"

const VALIDATION_TYPES = {
    REQUIRED: "required",
    EMAIL: "email",
}

const useCampaignForm = ({ formData, apiCallback }) => {
    const initialValues = formData?._fields?.reduce((acc, field) => {
        if (field.type === "checkbox") {
            acc[field.name] = field?.placeholder || false
        } else if (field.type === "input") {
            acc[field.name] = ""
        } else if (field.type === "recaptcha") {
            acc[field.name] = ""
        }
        return acc
    }, {})

    const reviewSchemaObject = formData?._fields?.reduce((acc, field) => {
        if (field.validations && field.validations.length > 0 && field.type === "input") {
            const validations = field.validations.reduce((acc, validation) => {
                switch (validation.type) {
                    case VALIDATION_TYPES.REQUIRED:
                        acc = acc.required(validation.message)
                        break
                    case VALIDATION_TYPES.EMAIL:
                        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                        acc = acc.test(VALIDATION_TYPES.EMAIL, validation.message, value => emailRegex.test(value))
                        break
                    default:
                        break
                }
                return acc
            }, yup.string())
            acc[field.name] = validations
        }
        if (field.validations && field.validations.length > 0 && field.type === "checkbox") {
            const validations = field.validations.reduce((acc, validation) => {
                switch (validation.type) {
                    case VALIDATION_TYPES.REQUIRED:
                        acc = acc.oneOf([true], validation.message)
                        break
                    default:
                        break
                }
                return acc
            }, yup.boolean())
            acc[field.name] = validations
        }
        if (field.validations && field.validations.length > 0 && field.isRequired && field.type === "recaptcha") {
            const validations = field.validations.reduce((acc, validation) => {
                switch (validation.type) {
                    case VALIDATION_TYPES.REQUIRED:
                        acc = acc.required(validation.message)
                        break
                    default:
                        break
                }
                return acc
            }, yup.string())
            acc[field.name] = validations
        }

        return acc
    }, {})
    const reviewSchema = yup.object().shape(reviewSchemaObject)

    const submitHandler = async params => {
        switch (formData._form) {
            case "uk_subscribe_form":
                try {
                    const response = await submitCampaignForm({
                        formParams: params,
                        language: "en-gb",
                        country: "UK",
                        dataProcessingText:
                            formData._fields.find(field => field.name === "dataProcessing")?.label || "",
                        campaignDetails: {
                            cdilSiteId: "www.mondelezafh.co.uk::gb::en::subscription",
                            domain: "www.mondelez-afh.co.uk",
                            campaign: "MOUK250101 MDLZ Foodservice Newsletter Registration",
                            transaction: "MOUK250101 MDLZ Foodservice Newsletter Registration (IN)",
                        },
                    })
                    apiCallback("uk_subscribe_form", {
                        success: response.success,
                    })
                } catch (error) {
                    apiCallback("uk_subscribe_form", {
                        success: false,
                    })
                }
                break
            default:
                throw new Error("Unknown form type")
        }
    }
    return {
        initialValues,
        reviewSchema,
        submitHandler,
    }
}

export default useCampaignForm
