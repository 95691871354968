import * as React from "react"
import PropTypes from "prop-types"
import { convertToWebpServer, checkPx, getImageType } from "./ResponsiveImage.helper"

const ResponsiveImage = props => {
    const { contentfulImagesComponent, className, alt, onClick, width, height, eventClass, eventLabel } = props

    if (!contentfulImagesComponent) {
        return <code>contentfulImagesComponent missing</code>
    }

    const { imageMobile, imageTablet, imageDesktop, imageDesktopLarge, customClass } = contentfulImagesComponent

    const tabletBreakpoint = checkPx(contentfulImagesComponent.tabletBreakpoint) || "768px"
    const desktopBreakpoint = checkPx(contentfulImagesComponent.desktopBreakpoint) || "1440px"
    const largeDesktopBreakpoint = checkPx(contentfulImagesComponent.largeDesktopBreakpoint) || "1728px"

    const imgAlt = alt || contentfulImagesComponent.altText || ""
    const classNameFinal = [className, eventClass, customClass].join(" ")
    const imgW = width || imageDesktop?.width
    const imgH = height || imageDesktop?.height

    const imageSources = []

    function pushImageSource(url, type, media = "", id) {
        const isSvg = /.svg/.test(url)
        const srcSet = isSvg ? url : convertToWebpServer(url)
        const source = { srcSet, media, type, id }
        imageSources.push(source)
    }

    if (!imageDesktop) {
        return <code>imageDesktop missing</code>
    }

    if (imageDesktopLarge) {
        const media = `(min-width: ${largeDesktopBreakpoint})`
        const type = getImageType(imageDesktopLarge.url)
        pushImageSource(imageDesktopLarge.url, type, media, imageDesktopLarge?.sys?.id)
    }

    if (imageDesktop && imageTablet) {
        const media = `(min-width: ${desktopBreakpoint})`
        const type = getImageType(imageDesktop.url)
        pushImageSource(imageDesktop.url, type, media, imageDesktop?.sys?.id)
    }

    if (imageDesktop && !imageTablet && imageMobile) {
        const media = `(min-width: ${tabletBreakpoint})`
        const type = getImageType(imageDesktop.url)
        pushImageSource(imageDesktop.url, type, media, imageDesktop?.sys?.id)
    }

    if (imageDesktop && !imageTablet && !imageMobile) {
        const type = getImageType(imageDesktop.url)
        pushImageSource(imageDesktop.url, type, "", imageDesktop?.sys?.id)
    }

    if (imageTablet && imageMobile) {
        const media = `(min-width: ${tabletBreakpoint})`
        const type = getImageType(imageTablet.url)
        pushImageSource(imageTablet.url, type, media, imageTablet?.sys?.id)
    }

    if (imageTablet && !imageMobile) {
        const type = getImageType(imageTablet.url)
        pushImageSource(imageTablet.url, type, "", imageTablet?.sys?.id)
    }

    if (imageMobile) {
        const type = getImageType(imageMobile.url)
        pushImageSource(imageMobile.url, type, "", imageMobile?.sys?.id)
    }

    return (
        <picture>
            {imageSources.map((source, index) => (
                <source key={`${source.id}-${index}`} srcSet={source.srcSet} media={source.media} type={source.type} />
            ))}
            <img
                src={imageDesktop?.url || imageTablet?.url || imageMobile?.url}
                alt={imgAlt}
                width={imgW}
                height={imgH}
                className={classNameFinal}
                onClick={onClick}
                data-action-detail={eventLabel}
            />
        </picture>
    )
}

ResponsiveImage.propTypes = {
    contentfulImagesComponent: PropTypes.shape({
        altText: PropTypes.string,
        imageMobile: PropTypes.shape({
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            description: PropTypes.string,
            url: PropTypes.string,
            sys: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        imageTablet: PropTypes.shape({
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            description: PropTypes.string,
            url: PropTypes.string,
            sys: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        imageDesktop: PropTypes.shape({
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            description: PropTypes.string,
            url: PropTypes.string,
            sys: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        imageDesktopLarge: PropTypes.shape({
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            description: PropTypes.string,
            url: PropTypes.string,
            sys: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
        tabletBreakpoint: PropTypes.string,
        desktopBreakpoint: PropTypes.string,
        largeDesktopBreakpoint: PropTypes.string,
        customClass: PropTypes.string,
    }).isRequired,

    className: PropTypes.string,
    alt: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    eventClass: PropTypes.string,
    eventLabel: PropTypes.string,
}

export default ResponsiveImage
