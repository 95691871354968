import React from "react"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import * as styles from "./DangerousHtml-tw-styles"

const DangerousHTML = props => {
    const { content, variant } = props
    const style = styles[variant] || styles.defaultStyle

    return (
        <div className={style.wrapper}>
            <div className={style.content}>{parse(content)}</div>
        </div>
    )
}

DangerousHTML.propTypes = {
    content: PropTypes.string,
    variant: PropTypes.string,
}

export default DangerousHTML
