export const defaultStyle = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const WRAPPED = {
    wrapper: "relative",
    content: "section-wrapper",
}

export const COOKIES_SNIPPET = {
    wrapper: "relative text-white font-bwSurco",
    content: "py-4 cookies-snippet",
}
export const COOKIES_BUTTON_SNIPPET = {
    wrapper: "relative text-white font-bwSurco",
    content: "pt-4",
}
