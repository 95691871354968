import React from "react"
import PropTypes from "prop-types"

const SocialLink = props => {
    const { url, label, icon, className, gaEventClass, gaEventLabel } = props
    return (
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`${className ?? ""} ${gaEventClass ?? ""}`}
            title={label ?? ""}
            data-action-detail={gaEventLabel || url || ""}
        >
            {!!icon && <img src={icon.url} width={icon.width} height={icon.height} alt={`${label ?? ""} icon`} />}
        </a>
    )
}

SocialLink.propTypes = {
    url: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.shape({
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    className: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default SocialLink
