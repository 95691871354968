const { fragment: LinkFragment } = require("../../UI/Link/Link.fragment")
const { fragment: SocialLinkFragment } = require("../../UI/SocialLink/SocialLink.fragment")

const fragment = `
    sys {
        id
    }
    linksCollection {
        items {
            __typename
            ... on Link {
                ${LinkFragment}
            }
            ... on SocialLink {
                ${SocialLinkFragment}
            }
        }
    }
`
module.exports = { fragment }
