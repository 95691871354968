const axios = require("axios")
const { v4: uuidv4 } = require("uuid")

const submitCampaignForm = async params => {
    const { formParams, language, country, dataProcessingText, campaignDetails } = params
    const payload = {
        lang: language,
        email: formParams.email,
        consents: [
            {
                name: "_dataProcessing",
                status: formParams.dataProcessing ? 1 : 0,
                text: dataProcessingText,
                subject: "_dataProcessing",
                lang: language,
            },
        ],
        legal: [],
        persona: {
            firstName: formParams.first_name,
            lastName: formParams.last_name,
            country: country,
        },
        external: {
            gRecaptchaResponse: formParams.recaptcha,
            cdilCrm: {
                cdilSiteId: campaignDetails.cdilSiteId,
                domain: campaignDetails.domain,
                campaign: campaignDetails.campaign,
                transaction: campaignDetails.transaction,
                data: {
                    participationId: uuidv4(),
                    confirmNewsletter: formParams.newsletter ? "1" : "0",
                },
            },
        },
    }
    try {
        if (!process.env.GATSBY_CAMPAIGN_API) {
            console.warn("GATSBY_CAMPAIGN_API is not provided")
        }
        const apiResponse = await axios.post(process.env.GATSBY_CAMPAIGN_API, payload)
        if (apiResponse.status === 200) {
            return {
                success: true,
            }
        } else {
            return {
                success: false,
            }
        }
    } catch (error) {
        console.error(`Error: ${error?.message || "Error submitting form"}`)
        return {
            success: false,
        }
    }
}

module.exports = { submitCampaignForm }
