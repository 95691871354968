/** @type {import('tailwindcss').Config} */

const colors = {
    white: "#FFFFFF",
    placeholderColor: "#B3B3B3",
    borderColor: "#D9D9D9",
    error: "#FF0400",
    black: "#000000",
    orange: "#E18719",
    orangeDark: "#B46414",
    darkGray: "#1E1E1E",
    purple: "#724D8D",
    darkPurple: "#4F2170",
}

module.exports = {
    content: [
        "./src/pages/**/*.{js,jsx,ts,tsx}",
        "./src/templates/**/*.{js,jsx,ts,tsx}",
        "./src/components/**/*.{js,jsx,ts,tsx}",
        "./src/assets/**/*.{js,jsx,ts,tsx}",
    ],
    safelist: [
        {
            pattern: /col-span-/,
            variants: ["sm", "md", "lg", "xl"],
        },
    ],
    theme: {
        screens: {
            xl: "1728px",
            lg: "1440px",
            md: "768px",
            sm: "402px",
            xsm: "376px",
        },
        fontFamily: {
            bwSurco: "BwSurcoDEMO",
            mdlzBiteType: "MDLZBITETYPE",
        },
        extend: {
            colors: {
                ...colors,
            },
            stroke: {
                ...colors,
            },
            fill: {
                ...colors,
            },
            placeholderColor: {
                ...colors,
            },
            flexGrow: {},
            spacing: {},
            fontSize: {},
            lineHeight: {},
            borderWidth: {},
            borderRadius: {},
            inset: {
                auto: "auto",
            },
            height: {},
            minHeight: {
                auto: "auto",
            },
            maxWidth: {},
            minWidth: {},
            maxHeight: {},
            opacity: {},
            zIndex: {},
            gap: {},
            backgroundImage: {},
            translate: {},
            transitionDuration: {},
            rotate: {},
            boxShadow: {},
            fontFamily: {},
        },
    },
    variants: {
        extend: {
            stroke: ["hover"],
        },
    },
    plugins: [
        function ({ addVariant }) {
            addVariant("child", "& > *")
            addVariant("child-hover", "& > *:hover")
        },
    ],
}
