export const gTagScriptConsent = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('consent', 'default', {
        ad_storage: "granted",
        analytics_storage: "granted",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        'wait_for_update': 500
    });
`
export const gTagScriptConsentProd = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('consent', 'default', {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        'wait_for_update': 500
    });
`

export const otGroupEvents = `
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        "event": "OneTrustGroupsUpdated",
        "OnetrustActiveGroups": ",C0001,C0002,C0003,C0004,"
    });
`
