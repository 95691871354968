const { fragment: LinkFragment } = require("../../UI/Link/Link.fragment")
const { fragment: ResponsiveImageFragment } = require("../../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: FooterLinksContainerFragment } = require("./FooterLinksContainer.fragment")

const query = (isPreview, locale) => `{
  footerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      internalTitle
      footerImageLink {
        ${LinkFragment}
      }
      footerImage {
        ${ResponsiveImageFragment}
      }
      socialLinks {
        ${FooterLinksContainerFragment}
      }
      baseLinks {
        ${FooterLinksContainerFragment}
      }
      copyright
    }
  }
}
`
module.exports = { query }
