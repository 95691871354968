import { useState } from "react"

const useCampaignFormViewManager = views => {
    const viewMap = views.reduce((acc, view) => {
        acc[view.name] = view
        return acc
    }, {})
    const [currentView, setCurrentView] = useState(viewMap.formView)

    const apiCallback = (formType, response) => {
        switch (formType) {
            case "uk_subscribe_form":
                if (response.success) {
                    setCurrentView(viewMap.thankYouView)
                }
                break
            default:
                setCurrentView(viewMap.formView)
                break
        }
    }

    // For Debugging
    const viewNames = Object.keys(viewMap)
    const currentIndex = views.indexOf(currentView)
    const currentViewName = viewNames[currentIndex]

    const handlePreviousView = () => {
        const previousIndex = (currentIndex - 1 + views.length) % views.length
        setCurrentView(views[previousIndex])
    }
    const handleNextView = () => {
        const nextIndex = (currentIndex + 1) % views.length
        setCurrentView(views[nextIndex])
    }

    const handleSetView = viewName => {
        setCurrentView(viewMap[viewName])
    }

    return {
        currentView,
        currentViewName,
        apiCallback,
        setCurrentView,
        handleSetView,
        handlePreviousView,
        handleNextView,
    }
}

export default useCampaignFormViewManager
