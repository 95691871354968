import React from "react"
import PropTypes from "prop-types"
import * as styles from "./AnimationWrapper-tw-styles"
import BubblesMobile from "./BubblesMobile"
import BubblesDesktop from "./BubblesDesktop"
import useScreenSize from "../../hooks/useScreenSize"
import { useMotionValue } from "framer-motion"

function Bubbles(props) {
    const { timeline = useMotionValue(0.7) } = props
    const style = styles.defaultStyle
    const screenSize = useScreenSize()
    return screenSize?.isMobile ? (
        <div className={style.bubblesWrapperMobile}>
            <BubblesMobile timeline={timeline} />
        </div>
    ) : (
        <div className={style.bubblesWrapperDesktop}>
            <BubblesDesktop timeline={timeline} />
        </div>
    )
}

export default Bubbles
Bubbles.propTypes = {
    timeline: PropTypes.object,
}
