import React from "react"

function CaretLeftIcon() {
    return (
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.65 14.762C12.1318 14.5976 11.9081 13.7742 11.8573 13.0776C11.8285 12.6865 11.963 10.0354 11.9781 9.64429C12.0138 8.61417 11.9918 7.9658 11.9987 5.93257C12.0028 4.6828 11.963 5.17731 11.9685 3.92754C11.9685 3.6797 12.0467 3.07126 11.8971 2.11984C11.8545 1.8485 12.0494 0.899431 11.7955 0.812511C11.2424 0.623401 10.4642 1.17898 9.86026 1.59714C6.82012 3.70436 4.91093 4.86134 1.69922 6.69841C1.33824 6.90514 0.149637 7.3597 0.0206191 7.82954C-0.160554 8.49084 0.899036 8.72811 1.3657 8.9736C3.01273 9.8381 4.6529 10.7214 6.28346 11.6211C8.13088 12.6407 11.0928 14.9511 11.65 14.762Z"
                fill="white"
            />
        </svg>
    )
}

export default CaretLeftIcon
