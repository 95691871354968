import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Footer-tw-styles"
import ResponsiveImage from "../../UI/ResponsiveImage/ResponsiveImage"
import SocialLink from "../../UI/SocialLink/SocialLink"
import Link from "../../UI/Link/Link"

const Footer = props => {
    const style = styles.defaultStyle
    const { footerImageLink, footerImage, socialLinks, baseLinks, copyright } = props
    return (
        <footer className={style.footer}>
            <div className={style.wrapper}>
                <div className={style.logoWrapper}>
                    {!!footerImageLink && !!footerImage && (
                        <Link {...footerImageLink}>
                            <ResponsiveImage contentfulImagesComponent={footerImage} />
                        </Link>
                    )}
                </div>
                <div className={style.socialLinksWrapper}>
                    {socialLinks?.linksCollection?.items.map((item, index) => (
                        <SocialLink
                            key={`${socialLinks.sys.id}_footer_social_link_${index}`}
                            {...item}
                            className={style.socialLink}
                        />
                    ))}
                </div>
                <div>
                    {copyright && <div className={style.copyright}>{copyright}</div>}
                    <div className={style.baseLinksWrapper}>
                        {baseLinks?.linksCollection?.items.map((item, index) => (
                            <Link
                                key={`${baseLinks.sys.id}_footer_base_link_${index}`}
                                {...item}
                                className={style.baseLink}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    footerImageLink: PropTypes.object,
    footerImage: PropTypes.object,
    socialLinks: PropTypes.object,
    copyright: PropTypes.string,
    baseLinks: PropTypes.object,
}

export default Footer
