export const defaultStyle = {
    wrapper:
        "relative w-full flex flex-col gap-[30px] md:gap-[50px] px-[20px] xsm:px-[28px] sm:px-[36px] py-[50px] md:px-[75px]",
    brandLogoWrapper: "relative flex items-center justify-center w-[161px] md:w-[171px] mx-auto",
    titleImageWrapper: "relative flex items-center justify-center max-w-[300px] md:max-w-[unset] md:w-[549px] mx-auto",
    content: "w-full flex flex-col justify-start gap-[30px]",
    copy: {
        paragraph: "font-bwSurco text-center text-white text-[12px] leading-[18px] md:text-[16px] md:leading-[24px]",
    },
    form: {
        wrapper: "w-full",
        formikContent: "w-full flex flex-col items-start justify-start gap-[20px]",
        submitButton: "w-full",
        formFields: {
            wrapper: "w-full grid grid-cols-10 gap-[20px]",
            input: {
                wrapper: "w-full flex flex-col items-start justify-start gap-[8px]",
                label: "font-bwSurco text-[16px] leading-[16px] text-white",
                input: "w-full rounded-[8px] font-bwSurco px-[16px] py-[12px] text-[16px] leading-[16px] bg-white text-darkGray placeholder-placeholderColor border-[1px] border-borderColor",
                errored: "border-error",
                errorMessage: "font-bwSurco text-[12px] leading-[12px] text-error",
            },
            checkbox: {
                wrapper: "w-full flex flex-col items-start justify-start gap-[8px]",
                checkboxWrapper: "custom-checkbox flex flex-row items-center justify-start gap-[12px]",
                label: "font-bwSurco text-[16px] leading-[22px] text-white [&_a]:underline [&_a]:cursor-pointer",
                errorMessage: "font-bwSurco text-[12px] leading-[12px] text-error",
            },
            recaptcha: {
                wrapper: "w-full flex flex-col items-start justify-start gap-[8px]",
                captchaWrapper: "flex",
                requiredIndicator: "font-bwSurco text-[16px] leading-[22px] text-white",
                errorMessage: "font-bwSurco text-[12px] leading-[12px] text-error",
            },
        },
    },
}
